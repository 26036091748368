import isNil from 'lodash/isNil';

import { OptionModel } from '@komo-tech/core/models/OptionModel';

import {
  ComboboxParsedItem,
  ComboboxParsedItemGroup,
  FilterOptionsInput
} from './_types';

export function isValueChecked(
  value: string | string[] | undefined | null,
  optionValue: string
) {
  return Array.isArray(value)
    ? value.includes(optionValue)
    : value === optionValue;
}

export function validateOptions(options: any[], valuesSet = new Set()) {
  if (!Array.isArray(options)) {
    return;
  }

  for (const option of options) {
    if ('items' in option) {
      validateOptions(option.items, valuesSet);
    } else {
      if (typeof option.value === 'undefined') {
        throw new Error('[@mantine/core] Each option must have value property');
      }

      if (typeof option.value !== 'string') {
        throw new Error(
          `[@mantine/core] Option value must be a string, other data formats are not supported, got ${typeof option.value}`
        );
      }

      if (valuesSet.has(option.value)) {
        throw new Error(
          `[@mantine/core] Duplicate options are not supported. Option with value "${option.value}" was provided more than once`
        );
      }

      valuesSet.add(option.value);
    }
  }
}

export function isEmptyComboboxData(data: ComboboxParsedItem[]) {
  if (data.length === 0) {
    return true;
  }

  for (const item of data) {
    if (!('items' in item)) {
      return false;
    }
    if ((item as ComboboxParsedItemGroup)?.items?.length > 0) {
      return false;
    }
  }

  return true;
}

export function groupOptions<TOption extends OptionModel = OptionModel>(
  data: TOption[]
): ComboboxParsedItem<TOption>[] {
  if (!data) {
    return [];
  }

  const sortedData: ComboboxParsedItem<TOption>[] = [];
  const unGroupedData: number[] = [];
  const groupedData = data.reduce((acc, item, index) => {
    if (item.group) {
      if (acc[item.group]) acc[item.group].push(index);
      else acc[item.group] = [index];
    } else {
      unGroupedData.push(index);
    }
    return acc;
  }, {});

  Object.keys(groupedData).forEach((groupName) => {
    sortedData.push({
      group: groupName,
      items: groupedData[groupName].map((index) => data[index])
    });
  });

  sortedData.push(...unGroupedData.map((itemIndex) => data[itemIndex]));

  return sortedData;
}

export function getOptionsLockup<TOption extends OptionModel = OptionModel>(
  options: ComboboxParsedItem<TOption>[]
): Record<string, TOption> {
  return options.reduce<Record<string, TOption>>((acc, item) => {
    if ('items' in item) {
      return { ...acc, ...getOptionsLockup(item.items) };
    }

    acc[(item as any).value] = item;

    return acc;
  }, {});
}

const defaultSearchPredicate: FilterOptionsInput['predicate'] = (
  item,
  options
) => {
  let itemLabel = item.label || '';
  if (options.ignoreCase) {
    itemLabel = itemLabel.toLowerCase();
  }

  if (!itemLabel && !options.search) {
    return true;
  }

  return itemLabel.includes(options.search);
};

export function defaultOptionsFilter<
  TOption extends OptionModel = OptionModel
>({
  data = [],
  search,
  limit = Infinity,
  predicate = defaultSearchPredicate,
  ignoreCase = true,
  splitWords = true
}: FilterOptionsInput<TOption>): ComboboxParsedItem<TOption>[] {
  let sanitizedSearch = (search || '').trim();
  if (ignoreCase) {
    sanitizedSearch = sanitizedSearch.toLowerCase();
  }

  const searchWords = (
    splitWords ? sanitizedSearch.split(' ') : [sanitizedSearch]
  ).filter((x) => !isNil(x));

  const result: ComboboxParsedItem<TOption>[] = [];

  for (let i = 0; i < data.length; i += 1) {
    const item = data[i];

    if (result.length === limit) {
      return result;
    }

    if ('items' in item && 'group' in item) {
      result.push({
        group: item.group,
        items: defaultOptionsFilter({
          data: item.items,
          search,
          limit: limit - result.length,
          ignoreCase,
          predicate,
          splitWords
        })
      } as ComboboxParsedItemGroup<TOption>);
    } else {
      if (
        searchWords.some((sw) => predicate(item, { search: sw, ignoreCase }))
      ) {
        result.push(item);
      }
    }
  }

  return result;
}

export function customSelectSearchFilter<
  TOption extends OptionModel = OptionModel
>(
  predicate: FilterOptionsInput<TOption>['predicate'],
  options?: Pick<FilterOptionsInput, 'splitWords' | 'ignoreCase'>
): (v: FilterOptionsInput<TOption>) => ComboboxParsedItem<TOption>[] {
  return (v) => {
    return defaultOptionsFilter({
      data: v.data,
      limit: v.limit,
      search: v.search,
      ignoreCase: options?.ignoreCase,
      predicate,
      splitWords: options?.splitWords
    });
  };
}

interface FilterPickedTagsInput<TOption extends OptionModel = OptionModel> {
  data: ComboboxParsedItem<TOption>[];
  value: TOption['value'][];
}

export function filterPickedMultiSelectValues<
  TOption extends OptionModel = OptionModel
>({ data, value }: FilterPickedTagsInput<TOption>) {
  const normalizedValue = value.map((item) => item.trim().toLowerCase());

  const filtered = data.reduce<ComboboxParsedItem[]>((acc, item) => {
    if ('items' in item) {
      acc.push({
        group: item.group,
        items: item.items.filter(
          (option) =>
            normalizedValue.indexOf(option.value.toLowerCase().trim()) === -1
        )
      });
    } else if (
      normalizedValue.indexOf(item.value.toLowerCase().trim()) === -1
    ) {
      acc.push(item);
    }

    return acc;
  }, []);

  return filtered;
}
