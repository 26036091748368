import cx from 'clsx';
import {
  CSSProperties,
  forwardRef,
  MouseEvent,
  MutableRefObject,
  ReactNode,
  useCallback
} from 'react';

import { Box, BoxProps } from '@/common/components/Display/Box';
import { useFlag } from '@komo-tech/core/hooks/useFlag';

import classes from './ElementHighlighter.module.css';

interface Props extends BoxProps {
  style?: CSSProperties;
  inline?: boolean;
  disabled?: boolean;
  force?: boolean;
  className?: string;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  children: ReactNode;
  tooltip?: ReactNode;
  elementRef?: MutableRefObject<null>;
  propagateMouseHovers?: boolean;
  wrapperStyle?: BoxProps['style'];
}

interface ControlledProps extends Props {
  hovered: boolean;
  onHoverChange: (hover: boolean) => void;
}
export const ControlledElementHighlighter = forwardRef<
  HTMLDivElement,
  ControlledProps
>(
  (
    {
      hovered,
      onHoverChange,
      children,
      disabled = false,
      force,
      onClick,
      inline,
      elementRef,
      tooltip,
      propagateMouseHovers,
      className,
      wrapperStyle,
      ...rest
    },
    ref
  ) => {
    const handleClick = (e: MouseEvent<HTMLDivElement>) => {
      if (!hovered || disabled) return;
      onClick?.(e);
    };

    const handleMouseEnter = (e: MouseEvent) => {
      if (disabled) {
        return;
      }
      if (!propagateMouseHovers) {
        e.stopPropagation();
      }

      if (!hovered) {
        onHoverChange(true);
      }
    };

    const handleMouseLeave = (e: MouseEvent) => {
      if (disabled) {
        return;
      }
      if (!propagateMouseHovers) {
        e.stopPropagation();
      }
      if (hovered) {
        onHoverChange(false);
      }
    };

    return (
      <Box
        ref={ref}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        role="button"
        data-element-highlighter-wrapper
        aria-disabled={disabled ? 'true' : 'false'}
        className={cx(classes.wrapper, className)}
        style={wrapperStyle}
      >
        <Box
          data-element-highlighter
          data-disabled={disabled}
          ref={elementRef}
          data-inline={inline}
          data-force={force}
          data-hovered={hovered}
          className={cx(
            'element-highlighter--highlighter',
            classes.highlighter,
            className
          )}
          {...rest}
        >
          {children}
        </Box>
        {tooltip && (
          <Box
            data-disabled={disabled}
            data-hovered={hovered}
            data-force={force}
            className={classes.tooltip}
          >
            {tooltip}
          </Box>
        )}
      </Box>
    );
  }
);

interface UnControlledProps extends Props {
  onHoverChange?: (hover: boolean) => void;
}

export const ElementHighlighter = forwardRef<HTMLDivElement, UnControlledProps>(
  ({ onHoverChange, ...rest }, ref) => {
    const hovered = useFlag();

    const handleHoverChange = useCallback((value: boolean) => {
      hovered.set(value);
      onHoverChange?.(value);
    }, []);

    return (
      <ControlledElementHighlighter
        {...rest}
        ref={ref}
        hovered={hovered.value}
        onHoverChange={handleHoverChange}
      />
    );
  }
);
