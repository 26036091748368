import { MantineSize, rem } from '@mantine/core';
import { useEyeDropper } from '@mantine/hooks';
import { FC } from 'react';

import { ActionButton } from '@/common/components/Button';
import { EyeDroppperIcon } from '@/common/components/Icons/EyeDroppperIcon';
import { NoOp } from '@komo-tech/core/utils/NoOp';

import { HsvaColor } from '../ColorPickerElement.types';
import { parseColor } from '../converters';

interface EyeDropperProps {
  onChange: (sRGBHex: HsvaColor) => void;
}

export const EyeDropperButton: FC<EyeDropperProps> = ({ onChange }) => {
  const { supported, open } = useEyeDropper();

  if (!supported) {
    return null;
  }

  return (
    <ActionButton
      variant="default"
      tooltip={{ label: 'Eye dropper' }}
      size="input-xs"
      onClick={() =>
        open()
          .then(({ sRGBHex }) => onChange(parseColor(sRGBHex)))
          .catch(NoOp)
      }
    >
      <EyeDroppperIcon />
    </ActionButton>
  );
};
