import {
  AlphaSlider as MantineAlphaSlider,
  Box as MantineBox,
  BoxProps as MantineBoxProps,
  ColorSwatch as MantineColorSwatch,
  createVarsResolver,
  ElementProps,
  Factory,
  factory,
  getSize,
  getSpacing,
  HueSlider as MantineHueSlider,
  MantineSize,
  StylesApiProps,
  useProps,
  useStyles
} from '@mantine/core';
import { useDidUpdate } from '@mantine/hooks';
import React, { useRef, useState } from 'react';

import { Divider } from '@/common/components/Display/Divider';
import { Stack } from '@/common/components/Display/Stack';
import { Text } from '@/common/components/Typography/Text';
import { useUncontrolled } from '@komo-tech/core/hooks/useUncontrolled';
import { ColorPalette } from '@komo-tech/core/models/ColorPalette';
import { useTheme } from '@/theme';

import { ColorPickerProvider } from './ColorPickerElement.context';
import classes from './ColorPickerElement.module.css';
import { ColorFormat, HsvaColor } from './ColorPickerElement.types';
import { convertHsvaTo, isColorValid, parseColor } from './converters';
import { EyeDropperButton } from './EyeDropper';
import { ColorPickerElementInput as Input } from './Input';
import { Saturation } from './Saturation';
import { Swatches } from './Swatches/Swatches';

export type ColorPickerElementStylesNames =
  | 'wrapper'
  | 'preview'
  | 'body'
  | 'sliders'
  | 'slidersWrapper'
  | 'slider'
  | 'sliderOverlay'
  | 'thumb'
  | 'saturation'
  | 'thumb'
  | 'saturationOverlay'
  | 'thumb'
  | 'swatches'
  | 'swatch'
  | 'input'
  | 'inputWrapper';

export type ColorPickerElementCssVariables = {
  wrapper:
    | '--cp-preview-size'
    | '--cp-width'
    | '--cp-body-spacing'
    | '--cp-swatch-size'
    | '--cp-thumb-size'
    | '--cp-saturation-height'
    | '--cp-input-wrapper-gap';
};

export interface __ColorPickerElementProps {
  /** Controlled component value */
  value?: string;

  /** Uncontrolled component default value */
  defaultValue?: string;

  /** Called when color changes */
  onChange?: (value: string) => void;

  /** Called when user stops dragging or changes value with arrow keys */
  onChangeEnd?: (value: string) => void;

  /** Color format, `'hex'` by default */
  format?: ColorFormat;

  /** Determines whether color picker should be displayed, `true` by default */
  withPicker?: boolean;

  /** Color palettes */
  palettes?: ColorPalette[];

  /** Number of swatches displayed in one row, `7` by default */
  swatchesPerRow?: number;

  /** Controls size of hue, alpha and saturation sliders, `'md'` by default */
  size?: MantineSize;

  //Shows the eye dropper button on the input
  withEyeDropper?: boolean;

  //What color to show when the input is empty
  emptyColor?: string;
}

export interface ColorPickerElementProps
  extends MantineBoxProps,
    __ColorPickerElementProps,
    StylesApiProps<ColorPickerElementFactory>,
    ElementProps<'div', 'onChange' | 'value' | 'defaultValue'> {
  __staticSelector?: string;

  /** Determines whether component should take 100% width of its container, `false` by default */
  fullWidth?: boolean;

  /** Determines whether interactive elements should be focusable, `true` by default */
  focusable?: boolean;

  /** Saturation slider `aria-label` */
  saturationLabel?: string;

  /** Hue slider `aria-label` */
  hueLabel?: string;

  /** Alpha slider `aria-label` */
  alphaLabel?: string;

  /** Called when one of the color swatches is clicked */
  onColorSwatchClick?: (color: string) => void;

  clearEnabled?: boolean;
}

export type ColorPickerElementFactory = Factory<{
  props: ColorPickerElementProps;
  ref: HTMLDivElement;
  stylesNames: ColorPickerElementStylesNames;
  vars: ColorPickerElementCssVariables;
}>;

const defaultProps: Partial<ColorPickerElementProps> = {
  swatchesPerRow: 10,
  withPicker: true,
  focusable: true,
  withEyeDropper: true,
  format: 'hexa',
  size: 'sm',
  emptyColor: '#FFFFFF',
  __staticSelector: 'ColorPickerElement'
};

const varsResolver = createVarsResolver<ColorPickerElementFactory>(
  (_, { size, swatchesPerRow }) => ({
    wrapper: {
      '--cp-preview-size': getSize(size, 'cp-preview-size'),
      '--cp-width': getSize(size, 'cp-width'),
      '--cp-body-spacing': getSpacing(size),
      '--cp-swatch-size': `${100 / swatchesPerRow!}%`,
      '--cp-thumb-size': getSize(size, 'cp-thumb-size'),
      '--cp-saturation-height': getSize(size, 'cp-saturation-height'),
      '--cp-input-wrapper-gap': getSpacing(size)
    }
  })
);

export const ColorPickerElement = factory<ColorPickerElementFactory>(
  (_props, ref) => {
    const props = useProps('ColorPickerElement', defaultProps, _props);
    const theme = useTheme();
    const {
      classNames,
      className,
      style,
      styles,
      unstyled,
      vars,
      format,
      value: valueProp,
      defaultValue,
      onChange,
      onChangeEnd,
      withPicker,
      size,
      saturationLabel,
      hueLabel,
      alphaLabel,
      focusable,
      palettes: palettesProp,
      swatchesPerRow,
      fullWidth,
      onColorSwatchClick,
      __staticSelector,
      withEyeDropper,
      emptyColor,
      clearEnabled,
      ...others
    } = props;

    const getStyles = useStyles<ColorPickerElementFactory>({
      name: __staticSelector!,
      props,
      classes,
      className,
      style,
      classNames,
      styles,
      unstyled,
      rootSelector: 'wrapper',
      vars,
      varsResolver
    });

    const formatRef = useRef(format);
    const valueRef = useRef<string>();
    const updateRef = useRef(true);
    const scrubTimeoutRef = useRef<number>(-1);
    const isScrubbingRef = useRef(false);
    const withAlpha =
      format === 'hexa' || format === 'rgba' || format === 'hsla';
    const palettes = palettesProp || theme.other.colors.palettes || [];

    const [value, setValue, controlled] = useUncontrolled({
      value: valueProp,
      defaultValue,
      finalValue: null,
      onChange
    });

    const [parsed, setParsed] = useState<HsvaColor>(
      parseColor(value || emptyColor)
    );
    const startScrubbing = () => {
      window.clearTimeout(scrubTimeoutRef.current);
      isScrubbingRef.current = true;
    };

    const stopScrubbing = () => {
      window.clearTimeout(scrubTimeoutRef.current);
      scrubTimeoutRef.current = window.setTimeout(() => {
        isScrubbingRef.current = false;
      }, 200);
    };

    const handleChange = (color: Partial<HsvaColor>) => {
      updateRef.current = false;
      setParsed((current) => {
        const next = { ...current, ...color };
        valueRef.current = convertHsvaTo(formatRef.current!, next);
        return next;
      });

      setValue(valueRef.current!);

      // Does not work any other way
      setTimeout(() => {
        updateRef.current = true;
      }, 0);
    };

    useDidUpdate(() => {
      const colorValue = isColorValid(value!) ? value : emptyColor;
      if (isColorValid(colorValue!) && !isScrubbingRef.current) {
        setParsed(parseColor(colorValue!));
      }
    }, [value]);

    useDidUpdate(() => {
      formatRef.current = format;
      setValue(convertHsvaTo(format!, parsed));
    }, [format]);

    const hasPalettes = (palettes || []).some((p) => p.hasItems());

    return (
      <ColorPickerProvider value={{ getStyles, unstyled }}>
        <MantineBox
          ref={ref}
          {...getStyles('wrapper')}
          size={size}
          mod={{ 'full-width': fullWidth }}
          {...others}
        >
          {withPicker && (
            <>
              <Saturation
                value={parsed}
                onChange={handleChange}
                onChangeEnd={({ s, v }) =>
                  onChangeEnd?.(
                    convertHsvaTo(formatRef.current!, {
                      ...parsed,
                      s: s!,
                      v: v!
                    })
                  )
                }
                color={value || emptyColor}
                size={size!}
                focusable={focusable}
                saturationLabel={saturationLabel}
                onScrubStart={startScrubbing}
                onScrubEnd={stopScrubbing}
              />

              <div {...getStyles('body')}>
                <div {...getStyles('slidersWrapper')}>
                  <div {...getStyles('sliders')}>
                    <MantineHueSlider
                      value={parsed.h}
                      onChange={(h) => handleChange({ h })}
                      onChangeEnd={(h) =>
                        onChangeEnd?.(
                          convertHsvaTo(formatRef.current!, { ...parsed, h })
                        )
                      }
                      size={size}
                      focusable={focusable}
                      aria-label={hueLabel}
                      onScrubStart={startScrubbing}
                      onScrubEnd={stopScrubbing}
                    />

                    {withAlpha && (
                      <MantineAlphaSlider
                        value={parsed.a}
                        onChange={(a) => handleChange({ a })}
                        onChangeEnd={(a) => {
                          onChangeEnd?.(
                            convertHsvaTo(formatRef.current!, { ...parsed, a })
                          );
                        }}
                        size={size}
                        color={convertHsvaTo('hex', parsed)}
                        focusable={focusable}
                        aria-label={alphaLabel}
                        onScrubStart={startScrubbing}
                        onScrubEnd={stopScrubbing}
                      />
                    )}
                  </div>

                  {withAlpha && (
                    <MantineColorSwatch
                      color={value || emptyColor}
                      radius="sm"
                      size="var(--cp-preview-size)"
                      {...getStyles('preview')}
                    />
                  )}
                </div>

                <div {...getStyles('inputWrapper')}>
                  <Input
                    format={format}
                    value={value}
                    clearEnabled={clearEnabled}
                    emptyColor={emptyColor}
                    onClear={() => {
                      if (clearEnabled) {
                        if (emptyColor) {
                          setParsed(parseColor(emptyColor!));
                        }
                        onChangeEnd?.('');
                      }
                    }}
                    onChangeEnd={(c) => {
                      handleChange(c);
                      onChangeEnd?.(convertHsvaTo(formatRef.current, c));
                    }}
                  />
                  {withEyeDropper && (
                    <EyeDropperButton
                      onChange={(c) => {
                        handleChange(c);
                        onChangeEnd?.(convertHsvaTo(formatRef.current, c));
                      }}
                    />
                  )}
                </div>
              </div>
            </>
          )}

          {hasPalettes && (
            <Stack mt="0.5rem" gap="0.25rem">
              {palettes
                .filter((p) => p.hasItems())
                .map((p) => (
                  <Stack gap="0.25rem" key={p.id.toString()}>
                    <Text size="xs">{p.label}</Text>
                    <Divider />
                    <Swatches
                      data={p.items}
                      swatchesPerRow={swatchesPerRow}
                      focusable={focusable}
                      setValue={setValue}
                      onChangeEnd={(color) => {
                        const convertedColor = convertHsvaTo(
                          format!,
                          parseColor(color)
                        );
                        onColorSwatchClick?.(convertedColor);
                        onChangeEnd?.(convertedColor);
                        if (!controlled) {
                          setParsed(parseColor(color));
                        }
                      }}
                    />
                  </Stack>
                ))}
            </Stack>
          )}
        </MantineBox>
      </ColorPickerProvider>
    );
  }
);

ColorPickerElement.classes = classes;
ColorPickerElement.displayName = 'ColorPickerElement';
